
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-grid',
  templateUrl: './modal-grid.component.html'
})
export class ModalGridComponent {
  @Input() accept: Function;
  @Input() cancel: Function;
  @Input() title: string;

  page_name: string = "modal-grid";
  //
  current_field_name: string = "";
  //
  fleet_name: string;
  current_fleet_name: string = "Defender";
  //
  star_system: string;
  current_star_system: string = "2024";
  //
  location: string;
  current_location: string = "4232";

  constructor() { };

  ngOnInit(): void {
    this.getCurrentFleetName();
    this.getCurrentStarSystem();
    this.getCurrentLocation();
  }

  getCurrentFleetName(): void {
    this.fleet_name = this.current_fleet_name;
  }

  //ANCHOR - get Current Star System
  getCurrentStarSystem(): void {
    this.star_system = this.current_star_system;
  }

  //ANCHOR - get Current Location
  getCurrentLocation(): void {
    this.location = this.current_location;
  }

  //ANCHOR - -- onSubmit --
  onSubmit() {
  }

}


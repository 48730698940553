import { Component } from '@angular/core';
import { SessionService  } from "../services/session.service";


@Component({
  selector: 'testing',
  templateUrl: './testing.component.html',
  styleUrl: './testing.component.css'
})
export class TestingComponent {
  myPageName: string = "testing";

  constructor(
    public session: SessionService,
  ) { };

}

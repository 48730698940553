<div id="_amk_" class="px-4 relative text-white flex flex-col items-left font text-left-bold text-2">

  <div class="m-2 p-2 w-4/5 flex flex-col xl:w-1/2">
    <form (ngSubmit)="onSubmit()">
      <table class="w-full">
        <tr>
          <td class="w-1/2">
            <div class="p-2 w-full text-black  flex flex-row bg-none rounded-2xl">
              <div class="px-1 w-full border border-gray-800 flex rounded-lg">
                <!--  -->
                <div class="px-2 uppercase border-r border-gray-800 bg-white flex items-center leading-6 whitespace-nowrap text-1 ism-blue rounded-l-xl h-14 bg-opacity-10">
                  email:
                </div>

                <div class="w-full bg-white flex items-center h-14">
                  <div class="w-full bg-white flex items-center ">
                    <!-- [(ngModel)]="parentNewEmail"  -->
                    <!-- <input id="inputEmail" name="parentNewEmail" type="email" size="70" placeholder="emailAddress@webmail.com" autocomplete class="h-14 w-full border-0 form-input bg-white bg-opacity-10 p-2 text-1.25 text-black placeholder-gray-800" [(ngModel)]="parentNewEmail" (change)="onParentChange(parentNewEmail)"> -->

                  </div>
                </div>
              </div>
            </div>

          </td>
          <td class="p-2 text-black">
            <!-- parentEmail: {{ this.parentNewEmail }} -->
          </td>
        </tr>
        <tr>
          <!-- [(ngModel)]="emailAddress" -->
          <td class="px-2">
            <!-- <input-email [fieldName]="'emailAddress'" [withIsmSvg]="false" [fieldLabel]="'Email:'" (change)="onParentChange(eventParentNewEmail)"></input-email> -->
          </td>
          <td class="p-2 text-black">
            <!-- parentEmail: {{ this.parentNewEmail }} -->
          </td>
        </tr>
      </table>
    </form>
  </div>
  <hr>
  <!-- Banner Demonstration -->
  <div class="w-full flex items-center justify-center">
    <button type="button" class="w-1/4 btn" (click)="session.bannerAlertMessage = 'This is an Alert'">
      Test Alert Banner
    </button>

    <button type="button" class="w-1/4 btn" (click)="session.bannerErrorMessage = 'This is an Error'">
      Test Error Banner
    </button>

    <button type="button" class="w-1/4 btn" (click)="session.bannerNotifyMessage = 'This is a Notification'">
      Test Banner Banner
    </button>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>

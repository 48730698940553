<div id="// modal-flex //" class="absolute w-full">
  <div id="// page-bg //" class="w-full bg-black bg-opacity-50 flex justify-center ">
    <!--  -->
    <div id="// modal-blue-bg //" class="my-4 w-2/5 z-20 relative top-20 text-left flex flex-col justify-center rounded-lg bg-accent-blue-dk-500">
      <div>old modal-header was here</div>
      <!-- <modal-header [title]="'Modal with Center Flex Layout'"></modal-header> -->
      <!--  -->
      <div id="// model-white-bg //" class="px-4 w-full text-black bg-white">
        <div class="py-4 w-full text-left text-1.25 font-semibold">Testing Field Alignment</div>
        <div id="// testing-alignment with flex //" class="w-full border-gray-500 border-0 flex flex-col items-center">
          <div class="w-3/5 flex flex-col">
            <form (ngSubmit)="onSubmit()">
              <!--  -->
              <div id="// input-text variations //" class="my-2 border-2 border-gray-500 rounded-md bg-pink-200">
                <div id="// Input-text Option-A //" class="px-2 text-left">
                  Input-text Option-A:
                  <!-- input-text no svg / no label  -->
                  <div class="my-2 flex items-center ">
                    <div class="px-2 w-1/3 inline-flex items-center whitespace-nowrap justify-end">Fleet Name</div>
                    <div>
                      old quick-html select component was here
                    </div>
                    <!-- <input-text [field_name]="fleet_name" [(mutableString)]="fleet_name"></input-text> -->
                  </div>
                </div>
                <div id="// Input-text Option-B //" class="px-2 text-left">
                  Input-text Option-B:
                  <div>
                    old quick-html input-text component was here
                  </div>
                  <!-- input-text no svg / yes label  -->
                  <!-- <input-text id="_input-text-2_" [attr.name]="fleet_name" [with_label]="true" [field_label]="'Fleet Name'" [(mutableString)]="fleet_name"></input-text> -->
                </div>
                <div id="// Input-text Option-C //" class="px-2 text-left">
                  Input-text Option-C:
                  <div>
                    old quick-html input-text component was here
                  </div>
                  <!-- YES svg / YES label -->
                  <!-- <input-text id="_input-text-2_" [attr.name]="fleet_name" [with_label]="true" [field_label]="'Fleet Name'" [with_ism_svg]="true" [ism_svg_name]="'rocket-launch'" [(mutableString)]="fleet_name"></input-text> -->

                </div>

              </div>

              <div id="// select-single variations //" class="border-2 border-gray-500 rounded-md bg-teal-100">
                <div id="// select-single Option-A //" class="px-2 text-left">
                  Select-single / Option-A:
                  <!--  -->
                  <div class="my-2 flex items-center ">
                    <div class="px-2 border-gray-500 border-none inline-flex items-center justify-end lanPixel2xl:whitespace-nowrap">Star System:</div>
                    <div class="w-full border-2 border-gray-500 inline-flex items-center justify-start ">
                      <div>
                        old quick-html select component was here
                      </div>
                      <!-- <select-single [field_name]="star_system" [(mutableString)]="star_system"></select-single> -->
                    </div>
                  </div>
                </div>
                <div id="// select-single Option-B //" class="px-2 text-left">
                  Select-single / Option-B:
                  <div>
                    old quick-html select component was here
                  </div>
                  <!-- with Label -->
                  <div class="my-2 flex items-center ">
                    <!-- <select-single [field_name]="location" [(mutableString)]="location"></select-single> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="// Value Check //" class="my-2 p-2 flex flex-col">
          CHECK VALUES
          <div>fleet name: {{ this.fleet_name }}</div>
          <div>star system: {{ this.star_system }}</div>
          <div>location: {{ this.location }}</div>
        </div>
      </div>
      <!--  -->
      <!-- <modal-footer></modal-footer> -->
      <div>old modal-footer was here</div>
    </div>
  </div>
</div>

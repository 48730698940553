
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-flex',
  templateUrl: './modal-flex.component.html'
})
export class ModalFlexComponent {
  @Input() accept: Function;
  @Input() cancel: Function;
  @Input() title: string;

  myPageName: string = "modal-grid";
  //
  field_name: string = "fieldTEXT";
  current_field_name: string = "";
  //
  fleet_name: string;
  current_fleet_name: string = "Defender";
  //
  star_system: string;
  current_Star_system: string = "2024";
  //
  location: string;
  current_location: string = "4232";

  constructor() { };

  ngOnInit(): void {
    this.getCurrentFleetName();
    this.getCurrentStarSystem();
    this.getCurrentLocation();
  }

  getCurrentFleetName(): void {
    this.fleet_name = this.current_field_name;
  }

  getCurrentStarSystem(): void {
    this.star_system = this.current_Star_system;
  }

  getCurrentLocation(): void {
    this.location = this.current_location;
  }

  onSubmit() {
  }

}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated, authorizedMarshal } from '../auth/auth.guard';

import { TestingRoutes } from "./testingRoutes";
import { PlaygroundComponent } from "./playground/playground.component";
import { ModalFlexComponent } from "./modal-flex/modal-flex.component";
import { ModalGridComponent } from "./modal-grid/modal-grid.component";
import { TestingComponent } from './testing.component';

const routes: Routes = [
  {
    path: 'testing',
    component: TestingComponent,
    canActivate: [authenticated],
    children: [
      {
        path: '',
        redirectTo: '/testing/playground',
        pathMatch: 'full',
      },
      {
        path: 'playground',
        component: PlaygroundComponent,
      },
      {
        path: 'modal-flex',
        component: ModalFlexComponent,
      },
      {
        path: 'modal-grid',
        component: ModalGridComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestingRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuickHtmlModule } from "../quick-html/quick-html.module";
import { SharedModule  } from "../shared/shared.module";
import { TestingRoutingModule } from './testing-routing.module';

import { TestingComponent  } from "./testing.component";
import { PlaygroundComponent } from "./playground/playground.component";
import { ModalFlexComponent  } from "./modal-flex/modal-flex.component";
import { ModalGridComponent } from "./modal-grid/modal-grid.component";

@NgModule({
  imports: [
    CommonModule,
    QuickHtmlModule,
    SharedModule,
    TestingRoutingModule,
  ],
  declarations: [
    PlaygroundComponent,
    ModalFlexComponent,
    ModalGridComponent,
    TestingComponent,
  ],
})
export class TestingModule { }

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { SessionService  } from "../../services/session.service";

@Component({
  selector: 'playground',
  templateUrl: './playground.component.html'
})
export class PlaygroundComponent implements OnInit {
  //ANCHOR - INPUTs
  @Input() parentNewEmail: string;
  @Input() childNewEmail: string;
  @Input() thePassword: string;

  //ANCHOR - output event Parent New Email
  @Output() eventParentNewEmail = new EventEmitter();
  //ANCHOR - Variables
  accountName: string;
  accountPwd: string;

  constructor(
    public session: SessionService,
  ) { };

  myPageName: string = "playground";

  //ANCHOR - ngOnInit
  ngOnInit(): void {
  }

  //SECTION - on Parent Change
  onParentChange(newEmail) {
    this.parentNewEmail = newEmail;
    // console.log('parentEmail: ', newEmail);
    console.log('parentNewEmail: ', this.parentNewEmail);
    // this.eventParentNewEmail.emit('tnk@tnk');
  };
  //!SECTION

  //SECTION - onSubmit
  onSubmit() {
  }
  //!SECTION
}

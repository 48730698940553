  <div class="absolute w-full h-full">
    <div class="w-full bg-gray-800 bg-opacity-40 flex justify-center ">
      <!--  -->
      <div id="_background_" class="my-4 w-2/5 z-20 relative top-20 text-left rounded-lg  bg-accent-blue-dk-500">
        <div>old modal-header was here</div>
        <!-- <modal-header [title]="'Modal with Grid'"></modal-header> -->
        <!--  -->
        <div id="_MODAL-CENTER_" class="px-4 w-full text-black bg-white flex flex-col">
          <div class="pt-4 w-full text-left text-1.25 font-semibold">Proposed Standard</div>
          <form (ngSubmit)="onSubmit()" >
            <div class="w-full flex justify-center">
              <div id="_AMK_GRID_" class="w-3/4 border-gray-500 border-none grid grid-cols-2 grid-rows-3 gap-2 auto-cols-auto auto-rows-auto">
                <!-- //ANCHOR - Fleet Label -->
                <div class="px-2 border-gray-500 border-none inline-flex items-center justify-end">Fleet Name:</div>

                <!-- //ANCHOR - Fleet Input -->
                <div class="px-2 border-2 border-gray-500 inline-flex items-center">
                  <div>
                    old quick-html select component was here
                  </div>
                  <!-- <input-text [field_name]="fleet_name" [(mutableString)]="fleet_name"  [ input_text_css ]="'w-full h-full px'" ></input-text> -->
                </div>

                <!-- //ANCHOR - Star System Label -->
                <div class="px-2 border-gray-500 border-none inline-flex items-center justify-end lanPixel2xl:whitespace-nowrap">Star System:</div>

                <!-- //ANCHOR - Star System Select -->
                <div class="w-full border-2 border-gray-500 inline-flex items-center justify-start ">
                  <div>
                    old quick-html select component was here
                  </div>
                  <!-- <select-single [field_name]="star_system" [(mutableString)]="star_system" [select_css]="'w-full form-select h-full'"></select-single> -->
                </div>
                <!-- //ANCHOR - Location Label -->
                <div class="px-2 border-0 border-gray-500 border-none inline-flex items-center justify-end">Location:</div>
                <!-- //ANCHOR - Location Input -->
                <div class="px-2 w-full border-2 border-gray-500 inline-flex items-center justify-start">
                  <div>
                    old quick-html select component was here
                  </div>
                  <!-- <input-text [field_name]="location" [(mutableString)]="location" [input_text_css]="'w-full h-full'"></input-text> -->
                </div>
              </div>
            </div>
          </form>
          <div>fleet name: {{ this.fleet_name }}</div>
          <div>star system: {{ this.star_system }}</div>
          <div>location: {{ this.location }}</div>
        </div>
        <!--  -->
        <div>old modal-footer was here</div>
      </div>
      <!--  -->
    </div>
  </div>
